import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/searchForm.css";

function DetailedLoadingRates() {
  const [loadingRates, setLoadingRates] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.icarslogistics.com/api/rates/loading/rates`)
      .then((response) => {
        setLoadingRates(response.data);
      });
  }, []);

  const headers = [
    "Origin",
    "Pack",
    "Load",
    "Operational Cost (A)",
    "Dray",
    "Pier Pass",
    "Operational Cost (B)",
    "Chassis",
    "Docs",
    "Cost Per File (A)",
    "Cost Per File (B)",
    "Congestion",
  ];

  const getPriceByFeeName = (fees, name) => {
    const fee = fees.find((f) => f.name.toLowerCase() === name.toLowerCase());
    return fee ? parseFloat(fee.price) : 0;
  };

  return (
    <div className="search-content">
      <div className="search-results-container">
        <h1>Loading Rates</h1>
        <div className="scroll-table">
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {loadingRates.map((rate, rateIndex) => (
                <tr key={rateIndex}>
                  <td>{rate.origin}</td>
                  <td>{rate.pack}</td>
                  {headers.slice(2).map((header, headerIndex) => (
                    <td key={headerIndex}>
                      {getPriceByFeeName(rate.fees, header)}
                    </td>
                  ))}
                  <td>
                    {headers
                      .slice(2) // slice at 2 because thats when the price values start, the other indexes are not numbers
                      .reduce(
                        (acc, header) =>
                          acc + getPriceByFeeName(rate.fees, header),
                        0
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DetailedLoadingRates;
