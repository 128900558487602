import React from "react";
import "../styles/vgmInstructions.css";

export const VgmInstructionsPage = () => {
  const websites = [
    {
      name: "MSC",
      link: "https://drive.google.com/file/d/1GvVGzIfWgR03nfiiJgfIxPeAg2W8Eyt8/view?usp=sharing",
    },
    {
      name: "ONELINE",
    },
    {
      name: "OOCL",
    },
    {
      name: "YANG MING",
    },
    {
      name: "ANL & CMA",
    },
    {
      name: "EVERGREEN",

      link: "",
    },
  ];
  return (
    <div className="carrierContainer">
      <h2>VGM Instructions</h2>
      <table class="vgmInstructionsTable">
        <thead>
          <tr>
            <th>Carrier</th>
            <th>Instructions</th>
          </tr>
        </thead>
        <tbody>
          {websites.map((website, index) => (
            <tr key={index}>
              <td>{website.name}</td>
              {website.link ? (
                <td>
                  <a
                    href={website.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open instructions
                  </a>
                </td>
              ) : (
                <td>Included in the shipping instructions</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
