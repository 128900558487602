import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDGkuTaay8rmvX_QJRiPLCt_fwZ2R5JPGs",
    authDomain: "direct-express-786d9.firebaseapp.com",
    projectId: "direct-express-786d9",
    storageBucket: "direct-express-786d9.appspot.com",
    messagingSenderId: "190228797347",
    appId: "1:190228797347:web:e718016f5b0922e92c2c16"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Auth service
const auth = getAuth();



export {app, auth, signInWithEmailAndPassword};