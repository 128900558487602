import React, { useState } from "react";
import "../styles/carrierContacts.css";
export const CarrierContactsPage = () => {
  const [currentTable, setCurrentTable] = useState("main");
  const carriers = [
    {
      name: "MSC",
      file: "https://docs.google.com/document/d/1IG3Vzp0ULv2nafxuBHgKMU-GKm3gucQb/edit?usp=sharing&ouid=106501975591987212895&rtpof=true&sd=true",
      fileName: "MSC Welcome Package",

      contacts: [
        {
          name: "Rebeca Flores",
          email: "rebeca.flores@msc.com",
          phoneNumber: "(346) 396-4572",
          department: "DRY GENERAL",
        },
        {
          name: "Chris Hines",
          email: "Chris.hines@msc.com",
          phoneNumber: "(843) 212-1585",
          department: "All EXPORT",
        },
        {
          name: "Jose Gomez",
          email: "jose.gomez@msc.com",
          phoneNumber: "(346) 396-4508",
          department: "DRY GENERAL",
        },
        {
          name: "Manual Booking process",
          email: "USA-Export.Booking@msc.com",
          phoneNumber: "(305) 421-6436",
          department: "Bookings",
        },
        {
          name: "Pending Booking Requests",
          email: "USA-Export.Booking@msc.com",
          phoneNumber: "(305) 421-6436",
          department: "Bookings",
        },
        {
          name: "After Booking Requests",
          email: "USA-export.customerservice@msc.com",

          phoneNumber: "(843)-654-6042",
          department: "Bookings",
        },
        {
          name: "Quotes Requests",
          email: "US038-export.quotes@msc.com",
          phoneNumber: "",
          department: "Quotes",
        },
        {
          name: "Updating SI",
          email: "USA-export.documentation@msc.com",
          phoneNumber: "",
          department: "Documentation",
        },
        {
          name: "Amending BL",
          email: "USA-export.documentation@msc.com",
          phoneNumber: "",
          department: "Documentation",
        },
        {
          name: "BL Release",
          email: "USA-export.documentation@msc.com",
          phoneNumber: "",
          department: "Documentation",
        },
        {
          name: "Manual SI Submission",
          email: "US038-manualshippinginstructions@msc.com",
          phoneNumber: "",
          department: "Shipping Instructions",
        },
        {
          name: "Hazardous Preliminary DGD",
          email: "US038-chshaz@msc.com",
          phoneNumber: "",
          department: "Hazardous Cargo",
        },
        {
          name: "Hazardous Final IMO",
          email: "US038-chshaz@msc.com",
          phoneNumber: "",
          department: "Hazardous Cargo",
        },
        {
          name: "Cargo Claims",
          email: "US038-cargoclaims@msc.com",
          phoneNumber: "",
          department: "Claims",
        },
      ],

      mainContacts: [
        {
          name: "Main Phone Number",
          contact: "(877) 672-7655 | 1 for export, 2 for existing booking",
        },
        {
          name: "Main Phone Number",
          contact: "(714) 708-3584",
        },
        {
          name: "Docs Email",
          contact: "USA-export.documentation@msc.com",
        },

        {
          name: "Sales Email",
          contact: "1 (657) 744-2719 / 1 (714) 708-3586",
        },

        {
          name: "Validated Titles Email",
          contact: "us038-longbeachtitle@msc.com / longbeachtitles@msc.com",
        },

        {
          name: "Bookings email",
          contact: "usa-export.bookings@msc.com",
        },
        {
          name: "Hazardous Booking Requests",
          contact: "us038-chshaz@msc.com",
        },

        {
          name: "Hazardous Declaration Final Approval",
          contact: "us038-chshaz@msc.com",
        },
      ],

      footer:
        "If Pier Pass has been claimed and doesn't show at terminal please request for edi to be released and it takes about 20 minutes to be resolved and updated.",
      websites: [
        {
          website: { name: "MSC Website", link: "https://msc.com" },
          username: "shipping@shipdei.com",
          password: "route66109*",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],
      contract: "23-642WW /  07/31/2024",
    },

    {
      name: "ONELINE",
      contacts: [
        {
          name: "Booking Changes",
          email: "na.csvc.bookingchange@one-line.com",
          phoneNumber: "844-413-6029 Opt 1 / then Opt 1",
          department: "Export Customer Service",
        },
        {
          name: "New Bookings",
          email: "na.csvc.newbook@one-line.com",
          phoneNumber: "",
          department: "Export Customer Service",
        },
        {
          name: "Import Customer Service",
          email: "us.one-line.com/LiveChat",
          phoneNumber: "844-413-6029 Opt 2",
          department: "Import Customer Service",
        },
        {
          name: "Hawaii Shipments",
          email: "na.iss.honolulu.agency@one-line.com",
          phoneNumber: "808-521-2111",
          department: "Hawaii Booking Contacts",
        },
        {
          name: "Reefer Bookings",
          email: "na.csvc.reeferbook@one-line.com",
          phoneNumber: "844-413-6029 Opt 1 / then Opt 2",
          department: "Reefer",
        },
        {
          name: "Reefer Inquiries",
          email: "na.csvc.reeferinquiry@one-line.com",
          phoneNumber: "",
          department: "Reefer",
        },
        {
          name: "Alaskan Reefer Shipments",
          email: "na.csvc.alaskareefer@one-line.com",
          phoneNumber: "",
          department: "Reefer",
        },
        {
          name: "Canadian Reefer Bookings & Changes",
          email: "na.csvc.canadareefercsvc@one-line.com",
          phoneNumber: "",
          department: "Reefer",
        },
        {
          name: "Special Commodities - Haz Inquiries",
          email: "NA.CSVC.specialcommodity@msg.one-line.com",
          phoneNumber: "844-413-6029 Opt 1 / then Opt 3",
          department: "Special Commodities",
        },
        {
          name: "HAZ DEC Submissions",
          email: "na.csvc.hazdec@one-line.com",
          phoneNumber: "",
          department: "Special Commodities",
        },
        {
          name: "Long-Haul Refrigerated Operations",
          email: "na.longhaulreefer.ops@one-line.com",
          phoneNumber: "",
          department: "Refrigerated Operations",
        },
        {
          name: "West-Coast Refrigerated Operations",
          email: "na.westcoast.reeferops@one-line.com",
          phoneNumber: "",
          department: "Refrigerated Operations",
        },
        {
          name: "East-Coast Refrigerated Operations",
          email: "na.eastcoast.reeferops@one-line.com",
          phoneNumber: "",
          department: "Refrigerated Operations",
        },
        {
          name: "Canada Refrigerated Operations",
          email: "na.canadareefer.ops@one-line.com",
          phoneNumber: "",
          department: "Refrigerated Operations",
        },
        {
          name: "North American EDI Support",
          email: "na.edi.support@one-line.com",
          phoneNumber: "",
          department: "EDI",
        },
        {
          name: "Maintenance and Repair",
          email: "na.mnr@one-line.com",
          phoneNumber: "866-437-3670 Opt 6 / Then Opt 6",
          department: "M&R",
        },
        {
          name: "M&R Billing Disputes",
          email: "na.invoices.mnr@one-line.com",
          phoneNumber: "",
          department: "M&R",
        },
        {
          name: "Export Documentation",
          email: "na.csvc.doc@one-line.com",
          phoneNumber: "844-413-6029 Opt 1 / then Opt 4",
          department: "Export Documentation",
        },
        {
          name: "Latin American Export Documentation",
          email: "na.csvc.latinamericadoc@one-line.com",
          phoneNumber: "",
          department: "Export Documentation",
        },
        {
          name: "Auto Titles",
          email: "na.csvc.autotitles@one-line.com",
          phoneNumber: "",
          department: "Export Documentation",
        },
        {
          name: "East Coast Late Gate Requests",
          email: "na.csvc.eclategate@one-line.com",
          phoneNumber: "",
          department: "Vessel Close Out",
        },
        {
          name: "West Coast Late Gate Requests",
          email: "na.csvc.wclategate@one-line.com",
          phoneNumber: "",
          department: "Vessel Close Out",
        },
        {
          name: "Digital Services Support",
          email: "na.csvc.digitalservicessupport@one-line.com",
          phoneNumber: "844-413-6029 Opt 3",
          department: "Digital Services",
        },
        {
          name: "Export Rail Billing Inquiries",
          email: "One.railbilling@one-line.com",
          phoneNumber: "866-437-3670",
          department: "Rail",
        },
        {
          name: "Equipment Return Locations",
          email: "www.returnlocation.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Domestic Rail Billing and Operations",
          email: "NA.Domestic.Operations@one-line.com",
          phoneNumber: "866-437-3670 Opt 2 / Then Opt 5",
          department: "Equipment",
        },
        {
          name: "Offhire Containers",
          email: "NA.EQCOFFHIRE@ONE-LINE.COM",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 1 / Then Opt 1",
          department: "Equipment",
        },
        {
          name: "SOC Containers",
          email: "NA.EQCSOC@one-line.com",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 1 / The Opt 4",
          department: "Equipment",
        },
        {
          name: "Northeast Empty Container Issues",
          email: "NA.EQCNorthEast@one-line.com",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 2",
          department: "Equipment",
        },
        {
          name: "Gulf/Southeast Empty Container Issues",
          email: "NA.EQCGulfSouthEast@one-line.com",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 3",
          department: "Equipment",
        },
        {
          name: "Midwest Empty Container Issues",
          email: "NA.EQCCentral@one-line.com",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 4",
          department: "Equipment",
        },
        {
          name: "Pacific Northwest Empty Container Issues",
          email: "na.EQCNorthWest@one-line.com",
          phoneNumber: "866-437-3670 Opt 3/ Then Opt 6",
          department: "Equipment",
        },
        {
          name: "Pacific Southwest Empty Container Issues",
          email: "NA.EQCSouthWest@one-line.com",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 5",
          department: "Equipment",
        },
        {
          name: "Rail Flip Requests",
          email: "na.railflip@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Depot Invoice Processing and Procurement",
          email: "NA.IOP.ProductAdmin.Depot@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Chassis and Genset Invoices and Disputes",
          email: "na.invoices.procurement@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Street Turn and Booking Swap Requests",
          email: "NA.EQCSTREETTURN@ONE-LINE.COM",
          phoneNumber: "866-437-3670 Opt 3 / Then Opt 1 / Then Opt 2",
          department: "Equipment",
        },
        {
          name: "Service Recovery Group",
          email: "one.servicerecovery@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Total Loss and Damaged Containers",
          email: "NA.TotalLoss@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Empty Availability and Pickup Changes",
          email: "na.emptyeqcinland@one-line.com",
          phoneNumber: "",
          department: "Equipment",
        },
        {
          name: "Cash Application",
          email: "na.ofs.cashreceipts@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Freight Release and GFR Processing",
          email: "na.freightrelease@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Refunds",
          email: "na.refunds@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Detention Billing",
          email: "na.ofs.det@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Demurrage Billing",
          email: "na.ofs.dem@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "General Billing",
          email: "na.fa.ar.billing@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Demurrage and Detention Disputes",
          email: "na.ofs.dd.recondisputes@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Collections and Invoice Inquiries",
          email: "na.accountsreceivable@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "Trucker Detention Collections",
          email: "na.ofs.truck.det@one-line.com",
          phoneNumber: "",
          department: "Accounts Receivable",
        },
        {
          name: "US Trucking Invoices",
          email: "na.ofs.ap@one-line.com",
          phoneNumber: "",
          department: "Accounts Payable",
        },
        {
          name: "CA Trucking Invoices",
          email: "na.ofs.ca@one-line.com",
          phoneNumber: "",
          department: "Accounts Payable",
        },
        {
          name: "Rail Invoices",
          email: "na.ofs.rp@one-line.com",
          phoneNumber: "",
          department: "Accounts Payable",
        },
        {
          name: "Port/Depot Invoices",
          email: "na.invoices.depot@one-line.com",
          phoneNumber: "",
          department: "Accounts Payable",
        },
        {
          name: "US Vendor Inquiries and Statements",
          email: "na.ofs.ap@one-line.com",
          phoneNumber: "571-258-4072",
          department: "Accounts Payable",
        },
        {
          name: "CA Vendor Inquiries and Statements",
          email: "na.ofs.ca@one-line.com",
          phoneNumber: "571-258-4072",
          department: "Accounts Payable",
        },
        {
          name: "Brokerage Inquiries",
          email: "na.brokerage@one-line.com",
          phoneNumber: "571-258-4072",
          department: "Brokerage",
        },
        {
          name: "Contract Filing",
          email: "gl.ofs.sc.esignature@one-line.com",
          phoneNumber: "",
          department: "Contract Filing",
        },
      ],
      mainContacts: [
        {
          name: "Main Phone Number",
          contact: "1 (844) 413-6029",
        },
        {
          name: "Local Main Phone Number",
          contact: "(804) 410-1255",
        },
        {
          name: "Bookings email",
          contact: "na.csvc.newbook@one-line.com",
        },
        {
          name: "Docs email",
          contact: "na.csvc.doc@one-line.com",
        },
        {
          name: "Sales Email",

          contact: "christopher.kiyoshi@one-line.com",
        },
        {
          name: "Sales Phone Number",
          contact: "(208) 901-7107",
        },
        {
          name: "Validated Titles Email",
          contact: "na.csvc.autotitles@one-line.com",
        },
        {
          name: "Hazardous Documents Email",
          contact: "na.csvc.hazdec@one-line.com",
        },
      ],
      websites: [
        {
          website: {
            name: "ONELINE Website",
            link: "https://www.one-line.com",
          },
          username: "DIRECT1EXP",
          password: "Route109!",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],
      contract: "LAX0349N23 / 12/31/2023",
    },
    {
      name: "OOCL",
      contacts: [
        {
          name: "SIMON",
          email: "simon.benson@oocl.com",
          phoneNumber: "",
          department: "SALES REP",
        },
        {
          name: "BOOKINGS",
          email:
            "CREATE/ ROLL ONLINE - https://www.oocl.com/eng/Pages/default.aspx",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "MASTER B/L",
          email: "SUBMIT ONLINE OOCL PAGE",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "TITLES",
          email: "SUBMIT ONLINE OOCL PAGE",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
      ],

      mainContacts: [
        {
          name: "Main Phone Number",
          contact: "1 (800) 822-6625",
        },
        {
          name: "Bookings",
          contact:
            "CREATE/ ROLL ONLINE - https://www.oocl.com/eng/Pages/default.aspx",
        },
        {
          name: "Documents Email",
          contact: "nadocwc@gmail.com",
        },

        {
          name: "Sales Number",
          contact: "(310) 809-3830",
        },
      ],
      websites: [
        {
          website: { name: "OOCL Website", link: "https://moc.oocl.com/sso" },
          username: "DIRECTEXP1",
          password: "ROUTE66109",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],

      contract: "MT246882 / 04/22/2024",
    },
    {
      name: "YANG MING",

      contacts: [
        {
          name: "ANDREW",
          email: "AndrewWilliams@us.yangming.com",
          phoneNumber: "",
          department: "SALES REP",
        },
        {
          name: "MASTER BL",
          email: "Jessicamendez@us.yangming.com & laxdc@us.yangming.com",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "TITLES",
          email: "Jessicamendez@us.yangming.com & icdc@yangming.com",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "BOOKINGS",
          email: "VIA EMAIL dennistsui@us.yangming.com",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
      ],

      mainContacts: [
        {
          name: "Titles & Docs",
          contact: "jessicaMendez@us.yangming.com & laxdc@us.yangming.com",
        },

        {
          name: "Docs, Titles, and D&D",
          contact: "ssc.shippinginstructions@anlusa.com",
        },

        {
          name: "Bookings",
          contact: "dennistsui@us.yangming.com & (626)-782-9797",
        },

        {
          name: "Sales",
          contact: "andrewwilliams@us.yangming.com & (626)-434-6587",
        },
      ],
      websites: [
        {
          website: {
            name: "YANG MING WEBSITE",
            link: "https://www.yangming.com/e-service/member_area/member_login.aspx",
          },
          username: "DIRECTEXPRCA",
          password: "109supportdirect",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],
      contract: "261421 / 04/30/2024",
    },

    {
      name: "ANL & CMA",

      contacts: [
        {
          name: "CHRISTINE CHEN",
          email: "usa.cchen@cma-cgm.com",
          phoneNumber: "(949) 471-3655",
          department: "SALES REP",
        },
        {
          name: "TITLES",
          email: "SSC.SHIPPINGINSTRUCTIONS@ANLUSA.COM",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "DRAIN & DISCONNECT",
          email: "SSC.SHIPPINGINSTRUCTIONS@ANLUSA.COM",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
      ],

      mainContacts: [
        {
          name: "Main Phone Number",
          contact: "1 (877)-266-5875",
        },

        {
          name: "Docs, Titles, and D&D",
          contact: "ssc.shippinginstructions@anlusa.com",
        },

        {
          name: "Customer Service",
          contact: "expcsv@anlusa.com",
        },
      ],

      websites: [
        {
          website: {
            name: "ANL & CMA Website",
            link: "https://mycustomerservice.cma-cgm.com/s/?language=en_US&cs-channel-ftr-wb=",
          },
          username: "dispatch@shipdei.com",
          password: "DirectExp1!",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],
      contract: "23-0420 / 10/31/2024",
    },
    {
      name: "EVERGREEN",
      contacts: [
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "LAXDOC@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "TFCEXP@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "PETERHUYNH@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "TIFFANYTRUONG@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "ELIZABETHTOOMALATAI@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "SHIPPING INSTRUCTIONS",
          email: "SHUWENHUNG@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "TFCEXP@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "DOCFAX@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "ELIZABETHTOOMALATAI@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "PETERHUYNH@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "TIFFANYTRUONG@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
        {
          name: "VALIDATED TITLES",
          email: "SHUWENHUNG@evergreen-shipping.us",
          phoneNumber: "",
          department: "DOCUMENTATION",
        },
      ],
      mainContacts: [
        {
          name: "Main Phone Number",
          contact: "(714) 822-6833",
        },

        {
          name: "Main Email",

          contact: "elizabethtoomalatai@evergreen-shipping.us",
        },

        {
          name: "Docs",
          contact: "laxdoc@evergreen-shipping.us",
        },

        {
          name: "Docs",
          contact: "tfcexp@evergreen-shipping.us",
        },
        {
          name: "Docs",

          contact: "peterhuynh@evergreen-shipping.us",
        },

        {
          name: "Docs",
          contact: "tiffanytruong@evergreen-shipping.us",
        },
        {
          name: "Sales",

          contact: "elizabethtoomalatai@evergreen-shipping.us & (714) 886-7840",
        },
        {
          name: "Validated Titles",

          contact: "tfcexp@evergreen-shipping.us",
        },
        {
          name: "Validated Titles",

          contact: "docfax@evergreen-shipping.us",
        },
        {
          name: "Validated Titles",

          contact: "peterhuynh@evergreen-shipping.us",
        },

        {
          name: "Validated Titles",

          contact: "tiffanytruong@evergreen-shipping.us",
        },
        {
          name: "Validated Titles",

          contact: "shuwenhung@evergreen-shipping.us",
        },
      ],
      websites: [
        {
          website: {
            name: "EVERGREEN WEBSITE",
            link: "https://www.shipmentlink.com",
          },

          username: "SHIPPING@SHIPDEI.COM",
          password: "xGW9PDdfF3",
        },
        {
          website: { name: "Intra Website", link: "https://login.inttra.com/" },
          username: "direct1",

          password: "route66109",
        },
        {
          website: {
            name: "Pier Pass",
            link: "https://www.pierpass-tmf.org/ ",
          },
          username: "direct1",

          password: "route66109",
        },
      ],
      contract: "SC96763 / 12/31/2023",
    },
  ];

  const renderMainTable = () => (
    <table>
      <thead>
        <tr>
          <th>Carrier Name</th>
          <th>Contacts</th>
          <th>Files</th>
          <th>Contract</th>
        </tr>
      </thead>
      <tbody>
        {carriers.map((carrier, index) => (
          <tr key={index}>
            <td>
              <strong>{carrier.name}</strong>
            </td>
            <td>
              {carrier.contact && (
                <a
                  href={carrier.contact}
                  target="_blank"
                  className="carrierContact"
                >
                  {carrier.contactName}
                </a>
              )}
              {carrier.contacts && (
                <a
                  href="#"
                  className="carrierContact"
                  onClick={() => setCurrentTable(carrier.name)}
                >
                  {`${carrier.name} Contacts`}
                </a>
              )}
            </td>
            <td>
              {carrier.file && (
                <a
                  href={carrier.file}
                  target="_blank"
                  className="carrierContact"
                >
                  {carrier.fileName}
                </a>
              )}
            </td>
            {carrier.contract && <td>{carrier.contract}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //https://docs.google.com/document/d/1IG3Vzp0ULv2nafxuBHgKMU-GKm3gucQb/edit?usp=sharing&ouid=106501975591987212895&rtpof=true&sd=true
  const renderContactsTable = (carrierName) => {
    const carrier = carriers.find((c) => c.name === carrierName);
    return (
      <div>
        <div className="carrierTitle">
          <h2>{`${carrier.name} Contacts`}</h2>
        </div>
        {carrier.websites && (
          <table>
            <thead>
              <tr>
                <th>Website</th>
                <th>Login</th>
                <th>Pasword</th>
              </tr>
            </thead>
            <tbody>
              {carrier.websites.map((website, index) => (
                <tr key={index}>
                  <td>
                    <a
                      href={website.website.link}
                      target="_blank"
                      className="websiteLink"
                      rel="noopener noreferrer"
                    >
                      {website.website.name}
                    </a>
                  </td>
                  <td>{website.username}</td>

                  <td>{website.password}</td>
                </tr>
              ))}
            </tbody>
            {carrier.footer && (
              <tfoot class="table-footer">
                <tr>
                  <td colSpan="3" class="footer-line-break">
                    <div>{carrier.footer}</div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        )}
        {carrier.mainContacts && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {carrier.mainContacts.map((contact, index) => (
                  <tr key={index}>
                    <td>{contact.name}</td>
                    <td>{contact.contact}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <table>
          <thead>
            <tr>
              <th>Contact Name</th>
              <th>Email</th>
              <th>Department</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {carrier.contacts.map((contact, index) => (
              <tr key={index}>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.department}</td>
                <td>{contact.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="carrierContainer">
      <div className="carrierTable">
        {currentTable === "main"
          ? renderMainTable()
          : renderContactsTable(currentTable)}
      </div>

      {currentTable !== "main" && (
        <button className="tableButton" onClick={() => setCurrentTable("main")}>
          Back to Carriers
        </button>
      )}
    </div>
  );
};
