import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../styles/home.css";
import { AuthenticationContext } from "./authenticationContext.js";

function HomePage() {
  const user = useContext(AuthenticationContext);
  return (
    <div>
      <main className="homeContent">
        <img
          src="/assets/logo banner.png"
          alt="Direct Express Logo"
          className="companyHomeLogo"
        />
        <img
          src="/assets/car.webp"
          alt="Direct Express Car"
          className="companyHomeCar"
        />
        <div className="buttonContent">
          <NavLink to="/airfreightcontacts" className="rateButton">
            Air Freight Contacts
          </NavLink>
          <a
            href="https://drive.google.com/file/d/1VpeTABkAEGQ9ZhwdETgiTnjkCET0yRZb/view?usp=sharing"
            target="_blank"
            className="rateButton"
          >
            Belgium Carrier Commodity Details
          </a>
          <NavLink to="/carrates" className="rateButton">
            Car Rates
          </NavLink>
          <NavLink to="/carriercontacts" className="rateButton">
            Carrier Contacts
          </NavLink>
          <NavLink to="/carrierdemurrage" className="rateButton">
            CBP Holds Carrier Demurrage Costs
          </NavLink>
          <NavLink to="/uscustoms" className="rateButton">
            CBP Holds Exams
          </NavLink>
          <NavLink to="/centraldispatchinlands" className="rateButton">
            Central Dispatch Inlands
          </NavLink>
          <NavLink to="/comparerates" className="rateButton">
            Compare Rates
          </NavLink>
          <NavLink to="/documentsrequiredexport" className="rateButton">
            Documents Required For Export
          </NavLink>
          <NavLink to="/exportlicenses" className="rateButton">
            Export Licenses
          </NavLink>
          <NavLink to="/fclcoloaders" className="rateButton">
            FCL Co Loaders
          </NavLink>
          <NavLink to="/rates" className="rateButton">
            FCL Rates
          </NavLink>
          <NavLink to="/hazprocedures" className="rateButton">
            Haz Procedures
          </NavLink>
          {user && user.email !== "moving@shipdei.com" && (
            <NavLink to="/loadingrates" className="rateButton">
              Loading Rates
            </NavLink>
          )}
          <NavLink to="/receivingwarehouses" className="rateButton">
            Receiving Warehouses
          </NavLink>
          <NavLink to="/rorocarriers" className="rateButton">
            Roro Carriers
          </NavLink>
          <NavLink to="/shiphawaiiandmore" className="rateButton">
            Shipping to HI, AK, PR
          </NavLink>
          <NavLink to="/terminalspierpass" className="rateButton">
            Terminals & Pier Pass
          </NavLink>

          {user &&
            (user.email === "dispatch@shipdei.com" ||
              user.email === "chris@shipdei.com" ||
              user.email === "chriswin12@gmail.com") && (
              <li className="rateButton">
                <NavLink to="/uploadrates">Upload Rates</NavLink>
              </li>
            )}
          <NavLink to="/uscontainerdray" className="rateButton">
            US Container Dray
          </NavLink>
          <a
            href="https://drive.google.com/file/d/15LehwtMOezryLCniYhVFwuiT8Z80didV/view?usp=sharing"
            target="_blank"
            className="rateButton"
          >
            US Customs Import Clark POA
          </a>
          <NavLink to="/vgminstructions" className="rateButton">
            VGM Instructions
          </NavLink>
        </div>
      </main>
    </div>
  );
}

export default HomePage;
