import React from "react";
import "../styles/fclCoLoaders.css";

export const FclCoLoadersPage = () => {
  const websites = [
    {
      website: { name: "SHIPCO", link: "https://www.shipco.com/" },
      username: "steve@shipdei.com",
      password: "Direct230!",
    },

    {
      website: {
        name: "Sea Shipping Line",
        link: "https://www.exportfile.net/ForwarderClient/login.aspx",
      },
      username: "chris@shipdei.com",
      password: "1hisship",
    },

    {
      website: { name: "CAROTRANS", link: "https://www.carotrans.com" },
      username: "DIRECTEXPLAXSF",
      password: "468104",
    },
  ];

  return (
    <div className="carrierContainer">
      <h2>FCL Co-Loaders</h2>
      <table class="fclCoLoaderTable">
        <thead>
          <tr>
            <th>Website</th>
            <th>Login</th>
            <th>Pasword</th>
          </tr>
        </thead>
        <tbody>
          {websites.map((website, index) => (
            <tr key={index}>
              <td>
                <a
                  href={website.website.link}
                  target="_blank"
                  className="websiteLink"
                  rel="noopener noreferrer"
                >
                  {website.website.name}
                </a>
              </td>
              <td>{website.username}</td>

              <td>{website.password}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
