import { useContext, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { AuthenticationContext } from "./authenticationContext.js";

const RequireLogin = () => {
  const user = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (
    user &&
    user.email == "moving@shipdei.com" &&
    location.pathname == "/loadingrates"
  ) {
    return (
      <div>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    );
  }

  if (
    user &&
    user.email != "dispatch@shipdei.com" &&
    user.email != "chris@shipdei.com" &&
    user.email != "chriswin12@gmail.com" &&
    location.pathname == "/uploadrates"
  ) {
    return (
      <div>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    );
  }
  if (user) {
    return <Outlet />;
  } else {
    return null;
  }
};

export default RequireLogin;
