// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword } from '../firebase/firebase.js';  // Import Firebase auth
import '../styles/login.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      console.log(auth);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      alert('Failed to log in: ' + error.message);
    }
  };

  return (
    <div class="loginContainer">
      <img src="/assets/logo banner.png" alt="Direct Express Logo" class="companyLogo" />
      <form onSubmit={handleLogin} class="loginForm">
        <input class="loginInput"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input class="loginInput"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button class="loginButton" type="submit">Login</button>
      </form>
    </div>
  );
};






export default LoginPage;