import React from "react";
import "../styles/information.css";
export const CarrierDemurragePage = () => {
  const files = [
    {
      name: "MSC Demurrage",
      link: "https://drive.google.com/file/d/1lC7mfGajF9f-fwngEWmy3er8l8tY7X0A/view?usp=sharing",
    },
    {
      name: "Yang Ming Demurrage",
      link: "https://drive.google.com/file/d/1ho7icrFR2bnmwbmn5LmTvX856rniV6lB/view?usp=sharing",
    },
    {
      name: "ONELINE Demurrage",
      link: "https://drive.google.com/file/d/1ofxfF4YIN7SmVxYt_DAgqUGiC6h2k5Bg/view?usp=sharing",
    },
    {
      name: "ANL Demurrage",

      link: "https://drive.google.com/file/d/1KRa1wI0rAMBq6ahxGLi0x7SMi3tg-7z8/view?usp=sharing",
    },
    {
      name: "OOCL Demurrage",

      link: "https://drive.google.com/file/d/1mCCLHVmSPlW11Svd2yw1LK1DVuCjUnpm/view?usp=sharing",
    },
    {
      name: "MAERSK Demurrage",
      link: "https://drive.google.com/file/d/1U225HHgA9a_-XTxFAkoBJJARFRSzn5rV/view?usp=sharing",
    },
  ];
  return (
    <div className="informationContainer">
      <h2>US Customs Carrier Demurrage</h2>
      <table class="informationTable">
        <thead>
          <tr>
            <th>Info</th>
            <th>Files</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              {file.link && (
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
