import React from "react";
import "../styles/information.css";
export const ShipToHawaiiAndMorePage = () => {
  const carriers = [
    {
      carrier: "PASHA HAWAII",
      service: "HAWAII",
      contact: "CUSTOMER SERVICE",
      email: "AutoService@PashaHawaii.com",
      tel: "1-866-363-7485",
      website: "WWW.PASHAHAWAII.COM",
      notes: "san diego",
    },
    {
      carrier: "MATSON",
      service: "HAWAII / ALASKA",
      contact: "CUSTOMER SERVICE",
      email: "general@matson.com",
      tel: "800-962-8766",
      website: "WWW.MATSON.COM",
      notes: "long beach, roro & container",
    },
    {
      carrier: "SALVAGE CARS",
      service: "HAWAII VIA PASHA",
      contact: "CUSTOMER SERVICE",
      email: "ADPTOWING08@AOL.COM",
      tel: "808-495-0095",
      website: "TBA",
      notes:
        "RORO SERVICE, ADP TOWING, FROM HAWAII TO SAN DIEGO, SPECIAL FORMS POA / RELEASE OF LIABILITY ARE NEEDED TO SHIP VIA RORO VIA PASHA",
    },
    {
      carrier: "CROWLEY",
      service: "PUERTO RICO",
      contact: "CUSTOMER SERVICE",
      email: "tba",
      tel: "800-276-9539",
      website: "puertorico.crowley.com",
      notes:
        "jacksonville, 1st get rate via website, 2nd making booking please call, 888-411-6442",
    },
    {
      carrier: "ALASKA BARGE",
      service: "ALASKA TO SEA",
      contact: "CUSTOMER SERVICE",
      email: "AMLCS@LYNDEN.COM",
      tel: "800-326-8346",
      website: "www.lynden.com/aml/",
      notes: "ALASKA MARINE, roro & container, AMY, BARGE, NON RUNNERS",
    },
    {
      carrier: "ALASKA MARINE",
      service: "SEATTLE TO HAWAII",
      contact: "CUSTOMER SERVICE",
      email: "CSWAKHI@LYNDEN.COM",
      tel: "800-326-8346",
      website: "www.lynden.com/aml/",
      notes: "RORO & CONTAINERS, NON RUNNERS",
    },
    {
      carrier: "ALASKA LOCAL TOW",
      service: "ALASKA TOWING",
      contact: "CUSTOMER SERVICE",
      email: "INFO@MIDNIGHTSUNTOWING.COM",
      tel: "907-646-0437",
      website: "midnightsuntowing.com",
      notes: "MIDNIGHT SUN TOWING, NON RUNNERS",
    },
    {
      carrier: "SEATTLE TO LAX INLAND",
      service: "NATIONWIDE TRANSPORT",
      contact: "CUSTOMER SERVICE",
      email: "DISPATCH@NTSLOGISTICS.COM",
      tel: "859-306-0619",
      website: "ntslogistics.com",
      notes: "NATIONWIDE TRANSPORT",
    },
  ];

  return (
    <div className="extraShippingInformationContainer">
      <h2>Carrier Shipping to Hawaii, Alaska, and Peurto Rico</h2>
      <div className="extraShippingInformationTable">
        <table>
          <thead>
            <tr>
              <th>Carrier</th>
              <th>Service</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Tel</th>
              <th>Website</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier, index) => (
              <tr key={index}>
                <td>{carrier.carrier}</td>
                <td>{carrier.service}</td>
                <td>{carrier.contact}</td>
                <td>{carrier.email}</td>
                <td>{carrier.tel}</td>
                <td>{carrier.website}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
