import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css"; // Importing the CSS file

export const DocumentsRequiredForExportPage = () => {
  const files = [
    {
      name: "Shipper's Export Declaration",
      link: "https://drive.google.com/file/d/14zB4FJuI3Iy_K3NhdFFg-Mf44NZ3UKo_/view?usp=sharing",
    },
    {
      name: "DEI POA SLI Instructions Terms & Conditions",
      link: "https://docs.google.com/document/d/1Rf5lcR391bhOS7Ov01VC7xSqQOQUgmJ2/edit?usp=sharing&ouid=106501975591987212895&rtpof=true&sd=true",
    },
    {
      name: "Commercial SLI Sample",
      link: "https://drive.google.com/file/d/1L85hLFlJGkI2eShKTCkFScIrHPahXgaB/view?usp=sharing",
    },
    {
      name: "Shipper's Letter of Instruction",
      link: "https://drive.google.com/file/d/15Fdh_sfww0kYckWHlS283b3BlxicjZif/view?usp=sharing",
    },
  ];

  const sections = [
    {
      title: <b>Commercial Cargo</b>,
      content: [
        "Commercial SLI, properly completed shipper, tax id, cnee, commodity, value, schedule b code.",
        "Commercial invoice",
        "Packing list",
        "*If HAZ cargo, please request HAZ declaration and MSDS from shipper.",
        "*Shipment must be booked, approved by carrier & declare as HAZ shipment. Haz trucking driver required.",
        <b>
          *Do not ship Haz cargo under any circumstances if not approved by
          carrier.
        </b>,
      ],
    },
    {
      title: <b>Shipping A Vehicle</b>,
      content: [
        "DEI POA, SLI",
        "Copy of passport or DL.",
        "Original title",
        "Bill of sale / commercial invoice",
        "*If electric or hybrid vehicle, this is HAZ cargo, please request HAZ declaration and MSDS from shipper.",
        "*Shipment must be booked, approved by carrier & declare as HAZ shipment. Haz trucking driver required.",
        <b>
          *Do not ship Haz cargo under any circumstances if not approved by
          carrier.
        </b>,
      ],
    },
    {
      title: <b>Loose Parts</b>,
      content: [
        "DEI POA, SLI",
        "Copy of passport or DL.",
        "Bill of sale / commercial invoice",
        "Packing list, total pcs, total value.",
        "*If HAZ cargo, please request HAZ declaration and MSDS from shipper.",
        "*Shipment must be booked, approved by carrier & declare as HAZ shipment. Haz trucking driver required.",
        <b>
          *Do not ship Haz cargo under any circumstances if not approved by
          carrier.
        </b>,
      ],
    },
    {
      title: <b>Household Goods And Personal Effects</b>,
      content: [
        "DEI POA, SLI",
        "Copy of passport or DL.",
        "Full Packing list, total pcs, total value.",
        "*If HAZ cargo, please request HAZ declaration and MSDS from shipper.",
        "*Shipment must be booked, approved by carrier & declare as HAZ shipment. Haz trucking driver required.",
        <b>
          *Do not ship Haz cargo under any circumstances if not approved by
          carrier.
        </b>,
      ],
    },
  ];
  const [activeSections, setActiveSections] = useState(
    sections.map(() => true)
  );

  const toggleAccordion = (index) => {
    setActiveSections((prevActiveSections) => {
      const newActiveSections = [...prevActiveSections];
      newActiveSections[index] = !newActiveSections[index];
      return newActiveSections;
    });
  };

  return (
    <div className="container">
      <h1>Documents Required For Export</h1>
      <p>
        Updated, please request the following from all customers, shippers, or
        agents:
      </p>
      <p>
        This is required so that we can file AES and clear US customs for all
        cargo and vehicles. The shipper and cnee all must match in the POA/SLI,
        commercial invoice, packing list, original title, and bill of sale for
        us to file AES, US customs cover letter, and HBL.
      </p>
      {sections.map((section, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className={`accordion-title ${
              activeSections[index] ? "active" : ""
            }`}
            aria-expanded={activeSections[index]}
          >
            {section.title}
            <span className="arrow">
              {activeSections[index] ? (
                <RiArrowDropUpLine size={24} />
              ) : (
                <RiArrowDropDownLine size={24} />
              )}
            </span>
          </button>
          {activeSections[index] && (
            <div className="accordion-content">
              {section.content.map((item, idx) => (
                <div key={idx} className="accordion-content-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                {file.link && (
                  <td>
                    <a
                      href={file.link}
                      target="_blank"
                      className="websiteLink"
                      rel="noopener noreferrer"
                    >
                      Click here to open
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
