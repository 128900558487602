import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import "../styles/searchForm.css";

function SearchForm() {
  const [inputOrigin, setInputOrigin] = useState("");
  const [inputDestination, setInputDestination] = useState("");
  const [inputContainer, setInputContainer] = useState("40'HC");
  const [inputCarrier, setInputCarrier] = useState("YANG");
  const [inputCarrier2, setInputCarrier2] = useState("EVERGREEN");
  const [inputCarSize, setInputCarSize] = useState("all");
  const [suggestions, setSuggestions] = useState({
    origins: [],
    destinations: [],
    sizes: [],
  });

  const [inputFieldsValid, setInputFieldsValid] = useState(true);

  const [searchResults, setSearchResults] = useState({});

  useEffect(() => {
    // Make a GET request to fetch suggestions from your server

    //axios.defaults.baseURL = 'http://localhost:8080'
    axios
      .get(
        /*"/api/rates/getSuggestions"*/ "https://api.icarslogistics.com/api/rates/getSuggestions?rateType=ofrRates"
      )
      .then((response) => {
        console.log("yo");
        console.log(response);
        setSuggestions(response.data); // Assuming the suggestions are in the response's data property
        console.log(suggestions);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error(error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "origin":
        console.log(name, value);
        setInputOrigin(value);
        break;
      case "destination":
        setInputDestination(value);
        break;
      case "size":
        setInputCarSize(value);
        break;
      case "carrier":
        setInputCarrier(value);
        break;
      case "container":
        setInputContainer(value);
      case "carrier2":
        setInputCarrier2(value);
      default:
        break;
    }
  };

  const getSuggestions = (value) => {
    const inputValue = value.input.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 0) {
      if (value.type == "Origin") {
        return suggestions.origins.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }

      if (value.type == "Destination") {
        return suggestions.destinations.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }
    } else if (inputLength <= 0) {
      console.log(suggestions, "empty");
      if (value.type == "Origin") return suggestions.origins; // Return an empty array when there's no input

      if (value.type == "Destination") return suggestions.destinations;
    }
  };

  function shouldRenderSuggestions() {
    return true;
  }

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
  const handleSearch = async () => {
    if (inputOrigin.trim() === "" || inputDestination.trim() === "") {
      setInputFieldsValid(false);
      setSearchResults({});

      return; // Stop further processing if fields are not valid
    }
    setInputFieldsValid(true);
    setSearchResults({});

    try {
      console.log();
      const response = await axios.get(
        /*`/api/rates/search?origin=${inputOrigin}&destination=${inputDestination}&container=${inputContainer}&carrier=${inputCarrier}`*/

        //console.log(`https://api.icarslogistics.com/api/rates/carsize?origin=${inputOrigin}&destination=${inputDestination}&size=${inputCarSize}`)
        `https://api.icarslogistics.com/api/rates/search/comparerates?origin=${inputOrigin}&destination=${inputDestination}&size=${inputContainer}&carrier=${inputCarrier}&carrier2=${inputCarrier2}`
      );

      //console.log(response.data);
      setSearchResults(response.data);

      console.log(response.data);
      // Handle the response from the server (e.g., update state or show a message)
      //console.log(response.data); // Assuming the server sends back a response with data
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);
    }
  };

  return (
    <div className="search-content">
      <div className="search-images">
        <img
          src="/assets/logo banner.png"
          alt="Direct Express Logo"
          class="search-logo"
        />
      </div>
      <div className="search-form-container">
        <Autosuggest
          suggestions={getSuggestions({ input: inputOrigin, type: "Origin" })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "origin",
            value: inputOrigin,
            onChange: (e, { newValue }) => {
              setInputOrigin(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Origin",
          }}
        />

        <Autosuggest
          suggestions={getSuggestions({
            input: inputDestination,
            type: "Destination",
          })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "destination",
            value: inputDestination,
            onChange: (e, { newValue }) => {
              setInputDestination(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Destination",
          }}
        />

        <select
          className="formInput"
          name="container"
          value={inputContainer}
          onChange={handleInputChange}
        >
          <option value="20'STD">20'STD</option>
          <option value="40'HC">40'HC</option>
          <option value="45'HC">45'HC</option>
        </select>

        <select
          className="formInput"
          name="carrier"
          value={inputCarrier}
          onChange={handleInputChange}
        >
          <option value="OOCL">OOCL</option>
          <option value="YANG">YANGMING</option>
          <option value="EVERGREEN">EVERGREEN</option>
          <option value="ANL">ANL</option>
          <option value="MSC">MSC</option>
          <option value="ONE">ONE LINE</option>
        </select>

        <select
          className="formInput"
          name="carrier2"
          value={inputCarrier2}
          onChange={handleInputChange}
        >
          <option value="OOCL">OOCL</option>
          <option value="YANG">YANGMING</option>
          <option value="EVERGREEN" selected>
            EVERGREEN
          </option>
          <option value="ANL">ANL</option>
          <option value="MSC">MSC</option>
          <option value="ONE">ONE LINE</option>
        </select>

        <button class="searchButton" onClick={handleSearch} tabIndex="0">
          Search
        </button>
      </div>
      {!inputFieldsValid && (
        <p className="error-message">Origin and Destination are required.</p>
      )}

      {searchResults.normalRates &&
        searchResults.normalRates.length <= 0 &&
        searchResults.arbRates &&
        searchResults.arbRates.length <= 0 && (
          <p className="error-message">No rates found.</p>
        )}
      <div className="search-results-container">
        <div className="search-results-main">
          <div className="scroll-table">
            {searchResults.length > 0 && ( // Check if normalRates even exists in the object, if not server did not send it that data
              <table className="compareRatesTable">
                <thead>
                  <tr>
                    <th>{searchResults[0].ports}</th>
                    <th>{searchResults[0].carrier}</th>
                    <th>
                      {searchResults[1] && searchResults[1].carrier
                        ? searchResults[1].carrier
                        : "CARRIER NOT FOUND"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PRICE</td>
                    <td>{searchResults[0].price}</td>
                    <td>{searchResults[1] ? searchResults[1].price : ""}</td>
                  </tr>
                  <tr>
                    <td>SIZE</td>
                    <td>{searchResults[0].size}</td>
                    <td>{searchResults[1] ? searchResults[1].size : ""}</td>
                  </tr>
                  <tr>
                    <td>COMMODITY</td>
                    <td>{searchResults[0].commodity}</td>
                    <td>
                      {searchResults[1] ? searchResults[1].commodity : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchForm;
