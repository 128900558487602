import React, { useState, useEffect, useContext } from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import "../styles/searchForm.css";
import { AuthenticationContext } from "./authenticationContext.js";

function SearchForm() {
  const [inputOrigin, setInputOrigin] = useState("");
  const [inputDestination, setInputDestination] = useState("");
  const [inputContainer, setContainer] = useState("all");
  const [inputCarrier, setCarrier] = useState("all");
  const [suggestions, setSuggestions] = useState({
    origins: [],
    destinations: [],
  });

  const [inputFieldsValid, setInputFieldsValid] = useState(true);
  const [searchResults, setSearchResults] = useState({});
  const [profitResults, setProfitResults] = useState([]);
  const [profitInputValue, setProfitInputValue] = useState(1000);
  const user = useContext(AuthenticationContext);
  useEffect(() => {
    // Make a GET request to fetch suggestions from your server

    if (user.email == "chris@shipdei.com") {
      setCarrier("MSC");
      console.log("carrier set");
    }

    //axios.defaults.baseURL = 'http://localhost:8080'
    axios
      .get(
        /*"/api/rates/getSuggestions"*/ `https://api.icarslogistics.com/api/rates/getSuggestions?rateType=ofrRates`
      )
      .then((response) => {
        console.log(response);
        setSuggestions(response.data); // Assuming the suggestions are in the response's data property
        console.log(suggestions);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error(error);
      });
  }, []);

  const handleProfitClick = (row) => {
    // Handle the click event here. You can log the data for demonstration purposes.
    console.log("Clicked row data:", row);
    axios
      .get(
        /*`/api/rates/loading/search?location=${row.origin}&destination=${row.destination}&container=${row.size}&price=${row.price}`*/
        `https://api.icarslogistics.com/api/rates/loading/search?location=${row.origin}&destination=${row.destination}&freeDays=${row.freeDays}&carrier=${row.carrier}&container=${row.size}&price=${row.price}&contract=${row.contract}&service=${row.service}`
      )
      .then((response) => {
        setProfitInputValue(1000);
        setProfitResults(response.data);
      });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "origin":
        setInputOrigin(value);
        break;
      case "destination":
        setInputDestination(value);
        break;
      case "container":
        setContainer(value);
        break;
      case "carrier":
        setCarrier(value);
        break;
      default:
        break;
    }
  };

  const handleProfitInputChange = (event) => {
    console.log(isNaN(event.target.value));
    console.log("yo");
    if (isNaN(event.target.value)) return;
    let onlyNumbers = event.target.value.replace(/[^0-9]/g, "");
    setProfitInputValue(onlyNumbers);
  };

  const handleProfitInputKeyPress = (event) => {
    const keyCode = event.keyCode || event.which; // Handle different browsers

    // Check if the pressed key is not a number (0-9)
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault(); // Prevent the key press
    }
  };
  const getSuggestions = (value) => {
    const inputValue = value.input.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 0) {
      if (value.type == "Origin") {
        return suggestions.origins.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }

      if (value.type == "Destination") {
        return suggestions.destinations.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }
    } else if (inputLength <= 0) {
      console.log(suggestions, "empty");
      if (value.type == "Origin") return suggestions.origins; // Return an empty array when there's no input

      if (value.type == "Destination") return suggestions.destinations;
    }
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const handleSearch = async () => {
    if (inputOrigin.trim() === "" || inputDestination.trim() === "") {
      setInputFieldsValid(false);
      setSearchResults({});
      setProfitResults([]);
      return; // Stop further processing if fields are not valid
    }
    setInputFieldsValid(true);
    setSearchResults({});
    setProfitResults([]);

    try {
      console.log("sent");
      console.log(
        `https://api.icarslogistics.com/api/rates/search?origin=${inputOrigin}&destination=${inputDestination}&container=${inputContainer}&carrier=${inputCarrier}`
      );
      const response = await axios.get(
        /*`/api/rates/search?origin=${inputOrigin}&destination=${inputDestination}&container=${inputContainer}&carrier=${inputCarrier}`*/
        `https://api.icarslogistics.com/api/rates/search?origin=${inputOrigin}&destination=${inputDestination}&container=${inputContainer}&carrier=${inputCarrier}`
      );
      setSearchResults(response.data);
      // Handle the response from the server (e.g., update state or show a message)
      console.log(response.data); // Assuming the server sends back a response with data
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);
    }
  };
  function shouldRenderSuggestions() {
    return true;
  }
  return (
    <div className="search-content">
      <div className="search-images">
        <img
          src="/assets/logo banner.png"
          alt="Direct Express Logo"
          class="search-logo"
        />
      </div>
      <div className="search-form-container">
        <Autosuggest
          suggestions={getSuggestions({ input: inputOrigin, type: "Origin" })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "origin",
            value: inputOrigin,
            onChange: (e, { newValue }) => {
              setInputOrigin(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Origin",
          }}
        />
        <Autosuggest
          suggestions={getSuggestions({
            input: inputDestination,
            type: "Destination",
          })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "destination",
            value: inputDestination,
            onChange: (e, { newValue }) => {
              setInputDestination(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Destination",
          }}
        />
        <select
          className="formInput"
          name="container"
          value={inputContainer}
          onChange={handleInputChange}
        >
          <option value="all">All Containers</option>
          <option value="20'STD">20'STD</option>
          <option value="40'HC">40'HC</option>
          <option value="45'HC">45'HC</option>
        </select>
        <select
          className="formInput"
          name="carrier"
          value={inputCarrier}
          onChange={handleInputChange}
        >
          <option value="all">All Carriers</option>
          <option value="OOCL">OOCL</option>
          <option value="YANG">YANGMING</option>
          <option value="EVERGREEN">EVERGREEN</option>
          <option value="ANL">ANL</option>

          <option value="ONE">ONE LINE</option>

          <option value="MSC">MSC</option>
        </select>
        <button class="searchButton" onClick={handleSearch} tabIndex="0">
          Search
        </button>
      </div>
      {!inputFieldsValid && (
        <p className="error-message">Origin and Destination are required.</p>
      )}

      {searchResults.normalRates &&
        searchResults.normalRates.length <= 0 &&
        searchResults.arbRates &&
        searchResults.arbRates.length <= 0 && (
          <p className="error-message">No rates found.</p>
        )}
      <div className="search-results-container">
        <div className="search-results-main">
          <div className="scroll-table">
            {profitResults.length > 0 && (
              <table class="profitTable">
                <thead>
                  <tr>
                    <th>Origin</th>
                    <th>Destination</th>

                    <th>Carrier</th>

                    <th>Route</th>

                    <th>Contract / Rate Exp.</th>

                    <th>Free Days O/D</th>
                    <th>Container</th>
                    <th>Pack</th>
                    <th>OFR</th>
                    <th>Loading</th>
                    <th>Total Cost</th>
                    <th>Profit</th>
                    <th>Sell</th>
                  </tr>
                </thead>
                <tbody>
                  {profitResults.map(
                    (
                      result,
                      index //check if arb rates are in the data, if so display them
                    ) => (
                      <tr key={index}>
                        <td>{result.location}</td>
                        <td>{result.destination}</td>

                        <td>{result.carrier}</td>

                        <td>
                          {result.service != "undefined" ? result.service : ""}
                        </td>
                        <td>{result.contract}</td>

                        <td>{result.freeDays}</td>
                        <td>{result.container}</td>
                        <td>{result.pack}</td>
                        <td>{result.shippingPrice}</td>
                        <td>
                          {user && user.email == "moving@shipdei.com"
                            ? `$${
                                parseInt(
                                  result.loadingPrice.replace(/[^0-9.]/g, "")
                                ) - 470
                              }`
                            : result.loadingPrice}
                        </td>
                        <td>
                          {user && user.email == "moving@shipdei.com"
                            ? `$${
                                parseInt(
                                  result.totalPrice.replace(/[^0-9.]/g, "")
                                ) - 470
                              }`
                            : result.totalPrice}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="profitInput"
                            value={profitInputValue}
                            onChange={handleProfitInputChange}
                            onKeyPress={handleProfitInputKeyPress}
                          />
                        </td>
                        <td>
                          {profitInputValue < 900 ? (
                            <span style={{ color: "red" }}>Profit too low</span>
                          ) : (
                            `$${
                              parseInt(
                                result.totalPrice.replace(/[^0-9.]/g, "")
                              ) +
                              (profitInputValue.length <= 0
                                ? 0
                                : parseInt(profitInputValue))
                            }`
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>

          <div className="scroll-table">
            {searchResults.normalRates &&
              searchResults.normalRates.length > 0 && ( // Check if normalRates even exists in the object, if not server did not send it that data
                <table>
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Commodity</th>
                      <th>Containers</th>
                      <th>OFR Price</th>

                      <th>Carrier</th>
                      <th>Route</th>
                      <th>Contract / Rate Exp.</th>

                      <th>Free Days O/D</th>
                      {searchResults.loadingRates && <th>Profit</th>}
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.normalRates.map(
                      (
                        result,
                        index //check if arb rates are in the data, if so display them
                      ) => (
                        <tr key={index}>
                          <td>{result.origin}</td>
                          <td>{result.destination}</td>
                          <td>{result.commodity}</td>
                          <td>{result.size}</td>
                          <td>{result.price}</td>
                          <td>{result.carrier}</td>
                          <td>{result.service}</td>
                          <td>{result.contract}</td>

                          <td>{result.freeDays}</td>
                          {searchResults.loadingRates ? (
                            <td>
                              <button
                                onClick={() => handleProfitClick(result)}
                                className="profit-button"
                              >
                                Calculate
                              </button>
                            </td>
                          ) : (
                            searchResults.loadingRates && <td></td>
                          )}
                          <td>{result.updated}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
          </div>

          <div className="scroll-table">
            {searchResults.arbRates && searchResults.arbRates.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Via Main Ports</th>
                    <th>Commodity</th>
                    <th>Container</th>
                    <th>Origin Price</th>
                    <th>Arbritrary Price</th>
                    <th>OFR Price</th>

                    <th>Carrier</th>

                    <th>Route</th>
                    <th>Contract / Rate Exp.</th>
                    <th>Free Days O/D</th>
                    {searchResults.loadingRates && <th>Profit</th>}
                    <th>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.arbRates.map(
                    (
                      result,
                      index //check if arb rates are in the data, if so display them
                    ) => (
                      <tr key={index}>
                        <td>{result.origin}</td>
                        <td>{result.destination}</td>
                        <td>{result.portsServed}</td>
                        <td>{result.commodity}</td>
                        <td>{result.size}</td>
                        <td>{result.arbOriginPrice}</td>
                        <td>{result.arbPrice}</td>
                        <td>{result.price}</td>
                        <td>{result.carrier}</td>

                        <td>{result.service}</td>
                        <td>{result.contract}</td>
                        <td>{result.freeDays}</td>
                        {searchResults.loadingRates ? (
                          <td>
                            <button
                              onClick={() => handleProfitClick(result)}
                              className="profit-button"
                            >
                              Calculate
                            </button>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>{result.updated}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {((searchResults.normalRates && searchResults.normalRates.length > 0) ||
          (searchResults.arbRates && searchResults.arbRates.length > 0)) && ( //wrapped the whole conditional in parentheses
          <table>
            <thead>
              <tr>
                <th className="charges__header">Additional Charges</th>
                <th className="charges__header">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hazardous Ocean Freight</td>
                <td>$300</td>
              </tr>
              <tr>
                <td>Hazardous Drayage</td>
                <td>$300</td>
              </tr>
            </tbody>

            <tfoot class="table-footer">
              <tr>
                <td colSpan="2" class="footer-line-break">
                  <div>
                    Note: Prices do not include: Container Service Charge & DTHC
                    (Destination Terminal Handling Charge)
                  </div>
                  <div>
                    Price does not include: Any US customs or any governmental
                    agency holds or exams
                  </div>
                  <div>
                    Price does not include: Any demurrage, storage, per diem
                    charges.
                  </div>
                  <div>
                    Price does not include: Any destination charges, unloading,
                    dray, custom entries, import duties and taxes.
                  </div>
                  <div>
                    Insurance costs an extra 2% for full coverage, minimum $175.
                    Claims require a deductible of $500 or 3%, whichever is
                    higher.
                  </div>
                  <div>
                    Insurance is additional at 1% for total loss only with a
                    minimum fee of $175 per policy.
                  </div>
                </td>
              </tr>
              {user && user.email == "moving@shipdei.com" && (
                <tr>
                  <td colSpan="2" class="footer-line-break">
                    <div>
                      HTX for Direct Express Inc internal use only. Need to add
                      to LAX PNL cost.
                    </div>
                    <div>Admin labor cost: 195</div>
                    <div>Errors & Omission: 175</div>
                    <div>Utilities 295</div>
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
}

export default SearchForm;
