import React from 'react';
import { getAuth, signOut } from 'firebase/auth'; // Import necessary functions from Firebase SDK
import '../styles/navigation.css';

function LogoutButton() {
  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      console.log('User logged out successfully');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <button className="navLogout" onClick={handleLogout}>Logout</button>
  );
}

export default LogoutButton;