import React from "react";
import "../styles/information.css";
export const AirFreightContactsPage = () => {
  const contacts = [
    {
      company: "CARS USA",
      phone: "310 695 6403",
      department: "air freight",
      name: "Robin",
      email: "robin@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "air freight",
      name: "Alberto",
      email: "alberto@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "air freight",
      name: "Judith",
      email: "judith@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "air freight",
      name: "Denise",
      email: "denise@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "ocean",
      name: "Cody",
      email: "cody@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "ocean",
      name: "Lorena",
      email: "lorena@carsusa.com",
    },
    {
      company: "CARS USA",
      department: "ocean",
      name: "Jessica",
      email: "jessica@carsusa.com",
      phone: "+1 (310) 695 6584",
    },
    {
      company: "CARS USA",
      department: "import",
      name: "Judith",
      email: "judith@carsusa.com",
      phone: "+1 (310) 738-5034",
    },
    {
      company: "CARS USA",
      department: "import",
      name: "Carol",
      email: "carol@carsusa.com",
    },
  ];
  return (
    <div className="extraShippingInformationContainer">
      <h2>Air Freight Contacts</h2>
      <div className="extraShippingInformationTable">
        <table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Name</th>
              <th>Department</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact, index) => (
              <tr key={index}>
                <td>{contact.company}</td>
                <td>{contact.name}</td>
                <td>{contact.department}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
