import React from "react";
import "../styles/information.css";

export const ExportLicensesPage = () => {
  const files = [
    {
      name: "Export License Instructions",
      link: "https://drive.google.com/file/d/1LIVClmGo_JoC1jgLo2G2vBW_Rj4qsVdX/view?usp=sharing",
    },

    {
      name: "Export License Application",
      link: "https://drive.google.com/file/d/1MNsxOXETLbp8ou48FIAozk3VsP9s2o7z/view?usp=sharing",
    },
    {
      name: "Export License AES Filing",
      link: "https://drive.google.com/file/d/1oI5RlVea5BABAE5lONj-VzX2IlYsmKBt/view?usp=sharing",
    },
  ];
  return (
    <div className="informationContainer">
      <h2>Export Licenses Info</h2>
      <table class="informationTable">
        <thead>
          <tr>
            <th>Info</th>
            <th>Files</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              {file.link && (
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
