import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css';

function Footer() {
  return (
    <footer>
        <div className="footer-content">
            <img src="/assets/logo.png" alt ="Company Logo" className ="footer-logo"/>
        </div>
    </footer>
  );
}






export default Footer;