import React from "react";
import "../styles/information.css";
export const TerminalsPierPassPage = () => {
  const book = [
    {
      terminal: "PIER PASS",
      carriers: "ALL CARRIERS",
      telephone: "(877) 863-3310",
      website: "https://www.pierpass-tmf.org/",
      username: "direct1",
      password: "route66109",
    },
    {
      terminal: "TTI (Total Terminals)",
      carriers: "MSC-HAPAG",
      telephone: "(562) 256-2649",
      website: "www.ttilgb.com",
      username: "directexp",
      password: "Route66",
    },
    {
      terminal: "ITS - International Terminal Service",
      carriers: "MSC-HAPAG",
      telephone: "(562) 435-7781",
      website: "https://tms.itslb.com/tms2/",
      username: "RIGOBERTOZ303",
      password: "Route@66109",
    },
    {
      terminal: "YTI - Yusen Terminal",
      carriers: "HAPAG - YANG MING- ONE - OOCL",
      telephone: "(310) 548-8000 (EXT2 EXPORT)",
      website: "https://yti.com/",
      username: "DIRECTEXP",
      password: "Route66$$",
    },
    {
      terminal: "PIER A - SSA (Tideworks)",
      carriers: "MSC - SUDU- HAPAG - ANL -CMA",
      telephone: "(562) 436-4489 / (866) 545-3142",
      website: "https://piera.tideworks.com/fc-PA/default.do",
      username: "emre@shipdei.com",
      password: "Route66",
    },
    {
      terminal: "Emodal Tracking",
      carriers: "FOR TRUCKERS TO MAKE APPOINTMENT",
      telephone: "",
      website: "www.emodal.com",
      username: "coadmin22402",
      password: "02163471387dei$",
    },
    {
      terminal: "WBCT Voyager",
      carriers: "YANG MING - ONE LINE - HAPAG",
      telephone: "(877)-391-2725",
      website:
        "https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA&_=637534919972857404",
      username: "K88866",
      password: "dIRECT90220",
    },
    {
      terminal: "APM Terminal (400)",
      carriers: "MSC - MAERSK - HAPAG",
      telephone: "(310) 221-4000",
      website:
        "https://www.apmterminals.com/en/los-angeles/online-services/vessel-schedule",
      username: "SHIPDEI",
      password: "Route66109",
    },
    {
      terminal: "TRAPAC",
      carriers: "HAPAG - YANG MING - ONE",
      telephone: "(877) 387-2722 / (310) 830-2000",
      website:
        "https://losangeles.trapac.com/quick-check/?terminal=LAX&transaction=booking_inquiry",
      username: "N/A",
      password: "N/A",
    },
    {
      terminal: "PCT - Pacific Cntr Terminal",
      carriers: "MSC - SUDU- HAPAG - ANL -CMA",
      telephone: "(562) 590-8728 / (866) 545-3142",
      website: "https://pct.tideworks.com/fc-PCT/default.do",
      username: "kenny@shipdei.com",
      password: "route66",
    },
    {
      terminal: "LBCT - Long Beach Cntr Terminal",
      carriers: "HAPAG - YANG MING- ONE - OOCL",
      telephone: "(562) 435-8585",
      website: "https://www.lbct.com/CargoSearch",
      username: "N/A",
      password: "N/A",
    },
    {
      terminal: "STE - Shippers Transport Terminal",
      carriers: "(USE EMODAL)",
      telephone: "(562) 435-1465 / (562) 901-3291",
      website: "www.emodal.com; http://www.shipperstransport.com/default.aspx",
      username: "rigo@shipdei.com",
      password: "route66",
    },
    {
      terminal: "Fenix Marine (FMS / APL / GGS / Eagle )",
      carriers: "EVER GREEN - APL - HAPAG - YANG MING - OOCL - ONE LINE",
      telephone: "(888) 815-3758",
      website: "https://fenixmarineservices.com/vessel-schedule-2/",
      username: "DIRECTEXP1",
      password: "route66",
    },
    {
      terminal: "ETS - Everport Terminal",
      carriers: "EVERGREEN",
      telephone: "(310) 221-0220",
      website: "https://www.etslink.com/",
      username: "rigo@shipdei.com",
      password: "Route66109",
    },
    {
      terminal: "C60 - Matson Terminal",
      carriers: "MATSON (RORO) - WALLENIUS",
      telephone: "(562) 495-8612",
      website: "https://m60.tideworks.com/fc-M60/default.do",
      username: "N/A",
      password: "N/A",
    },
    {
      terminal: "Savannah - Garden City Terminal",
      carriers: "SAVANNAH GA TERMINAL",
      telephone: "",
      website: "http://webaccess.gaports.com",
      username: "rzavalet",
      password: "zr3790",
    },
    {
      terminal: "MARINE TRAFFIC",
      carriers: "",
      telephone: "",

      website:
        "https://www.marinetraffic.com/en/ais/home/centerx:-118.266/centery:33.767/zoom:14",
      username: "",
      password: "",
    },
    {
      terminal: "BIG SCHEDULE",
      carriers: "",
      telephone: "",
      website: "https://www.bigschedules.com/tou#!/routes",
      username: "INFO@SHIPDEI.COM",
      password: "QpSpqTDR3DJetf6",
    },
    {
      terminal: "LOAD MATCH / FOR CONTAINER TRUCKING / DRAY ANY USA LOCATION",
      carriers: "",
      telephone: "",
      website: "https://loadmatch.com/",
      username: "rzavaleta",
      password: "shipdei",
    },
    {
      terminal: "PIER S",
      carriers: "",
      telephone: "",
      website: "",
      username: "",
      password: "",
    },
  ];
  return (
    <div className="extraInformationContainer">
      <h2>Terminals & Pier pass</h2>

      <div className="extraInformationTable">
        <table>
          <thead>
            <tr>
              <th>Terminal</th>
              <th>Carriers</th>

              <th>Telephone</th>

              <th>Website</th>
              <th>Username</th>

              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {book.map((b, index) => (
              <tr key={index}>
                <td>{b.terminal}</td>
                <td>{b.carriers}</td>
                <td>{b.telephone}</td>
                {b.website && (
                  <td>
                    <a
                      href={b.website}
                      target="_blank"
                      className="websiteLink"
                      rel="noopener noreferrer"
                    >
                      Click here to open
                    </a>
                  </td>
                )}
                <td>{b.username}</td>

                <td>{b.password}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
