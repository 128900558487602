import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import "../styles/searchForm.css";

function SearchForm() {
  const [inputOrigin, setInputOrigin] = useState("");
  const [inputDestination, setInputDestination] = useState("");
  const [inputCarSize, setInputCarSize] = useState("all");
  const [suggestions, setSuggestions] = useState({
    origins: [],
    destinations: [],
    sizes: [],
  });

  const [inputFieldsValid, setInputFieldsValid] = useState(true);

  const [searchResults, setSearchResults] = useState({});

  useEffect(() => {
    // Make a GET request to fetch suggestions from your server

    //axios.defaults.baseURL = 'http://localhost:8080'
    axios
      .get(
        /*"/api/rates/getSuggestions"*/ "https://api.icarslogistics.com/api/rates/getSuggestions?rateType=carRates"
      )
      .then((response) => {
        console.log("yo");
        console.log(response);
        setSuggestions(response.data); // Assuming the suggestions are in the response's data property
        console.log(suggestions);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error(error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "origin":
        console.log(name, value);
        setInputOrigin(value);
        break;
      case "destination":
        setInputDestination(value);
        break;

      case "size":
        setInputCarSize(value);
        break;
      default:
        break;
    }
  };

  const getSuggestions = (value) => {
    const inputValue = value.input.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 0) {
      if (value.type == "Origin") {
        return suggestions.origins.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }

      if (value.type == "Destination") {
        return suggestions.destinations.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
      }
    } else if (inputLength <= 0) {
      console.log(suggestions, "empty");
      if (value.type == "Origin") return suggestions.origins; // Return an empty array when there's no input

      if (value.type == "Destination") return suggestions.destinations;
    }
  };

  function shouldRenderSuggestions() {
    return true;
  }

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
  const handleSearch = async () => {
    if (inputOrigin.trim() === "" || inputDestination.trim() === "") {
      setInputFieldsValid(false);
      setSearchResults({});

      return; // Stop further processing if fields are not valid
    }
    setInputFieldsValid(true);
    setSearchResults({});

    try {
      console.log("sent");
      const response = await axios.get(
        /*`/api/rates/search?origin=${inputOrigin}&destination=${inputDestination}&container=${inputContainer}&carrier=${inputCarrier}`*/

        //console.log(`https://api.icarslogistics.com/api/rates/carsize?origin=${inputOrigin}&destination=${inputDestination}&size=${inputCarSize}`)
        `https://api.icarslogistics.com/api/rates/search/carsize?origin=${inputOrigin}&destination=${inputDestination}&size=${inputCarSize}`
      );

      setSearchResults(response.data);

      console.log(response.data);
      // Handle the response from the server (e.g., update state or show a message)
      //console.log(response.data); // Assuming the server sends back a response with data
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);
    }
  };

  return (
    <div className="search-content">
      <div className="search-images">
        <img
          src="/assets/logo banner.png"
          alt="Direct Express Logo"
          class="search-logo"
        />
      </div>
      <div className="search-form-container">
        <Autosuggest
          suggestions={getSuggestions({
            input: inputOrigin,
            type: "Origin",
          })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "origin",
            value: inputOrigin,
            onChange: (e, { newValue }) => {
              setInputOrigin(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Origin",
          }}
        />

        <Autosuggest
          suggestions={getSuggestions({
            input: inputDestination,
            type: "Destination",
          })}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={{
            name: "destination",
            value: inputDestination,
            onChange: (e, { newValue }) => {
              setInputDestination(newValue); // Update inputOrigin as the user types
            },
            placeholder: "Destination",
          }}
        />

        <select
          className="formInput"
          name="size"
          value={inputCarSize}
          onChange={handleInputChange}
        >
          <option value="all">All Car Sizes</option>
          {suggestions.sizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>

        <button class="searchButton" onClick={handleSearch} tabIndex="0">
          Search
        </button>
      </div>
      {!inputFieldsValid && (
        <p className="error-message">Origin and Destination are required.</p>
      )}

      {searchResults.normalRates &&
        searchResults.normalRates.length <= 0 &&
        searchResults.arbRates &&
        searchResults.arbRates.length <= 0 && (
          <p className="error-message">No rates found.</p>
        )}
      <div className="search-results-container">
        <div className="search-results-main">
          <div className="scroll-table">
            {searchResults.length > 0 && ( // Check if normalRates even exists in the object, if not server did not send it that data
              <table>
                <thead>
                  <tr>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Size</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map(
                    (
                      result,
                      index //check if arb rates are in the data, if so display them
                    ) => (
                      <tr key={index}>
                        <td>{result.origin}</td>
                        <td>{result.destination}</td>
                        <td>{result.size}</td>
                        <td>{result.price}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {searchResults.length > 0 && ( //wrapped the whole conditional in parentheses
          <table>
            <thead>
              <tr>
                <th className="charges__header">Additional Charges</th>
                <th className="charges__header">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hazardous Ocean Freight</td>
                <td>$300</td>
              </tr>
              <tr>
                <td>Hazardous Drayage</td>
                <td>$300</td>
              </tr>
            </tbody>

            <tfoot class="table-footer">
              <tr>
                <td colSpan="2" class="footer-line-break">
                  <div>
                    Price does not include: Any US customs or any governmental
                    agency holds or exams
                  </div>
                  <div>
                    Price does not include: Any demurrage, storage, per diem
                    charges.
                  </div>
                  <div>
                    Price does not include: Any destination charges, unloading,
                    dray, custom entries, import duties and taxes.
                  </div>
                  <div>
                    Insurance costs an extra 2% for full coverage, minimum $175.
                    Claims require a deductible of $500 or 3%, whichever is
                    higher.
                  </div>
                  <div>
                    Insurance is additional at 1% for total loss only with a
                    minimum fee of $175 per policy.
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
}

export default SearchForm;
